import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../config/api-config';


@Injectable()
export class DonationService {
  url = API_URL;

  initiateGroupDonationUrl = this.url + 'web-donate-group';
  initiateEventDonationUrl = this.url + 'web-donate-event';
  recordGroupTransactionUrl = this.url + 'group-donation-status';
  recordEventTransactionUrl = this.url + 'event-donation-status';


  constructor(private http: HttpClient) {}

  initiateGroupDonation(formData) {
    return this.http.post(this.initiateGroupDonationUrl, formData);
  }

  initiateEventDonation(formData) {
    return this.http.post(this.initiateEventDonationUrl, formData);
  }

  recordGroupTransaction(formData) {
    return this.http.post(this.recordGroupTransactionUrl, formData);
  }

  recordEventTransaction(formData) {
    return this.http.post(this.recordEventTransactionUrl, formData);
  }
}
