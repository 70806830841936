import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateformat'
})
export class DateFormatPipe implements PipeTransform {
    transform(title: string): string {
        var month = title.substring(5, 7)
        var day = title.substring(8,10)
        const year = title.substring(0,4)
         switch (day) {
             case '01':
                 day = "1";
                 break;
                 case '02':
                 day = "2";
                 break;
                 case '03':
                 day = "3";
                 break;
                 case '04':
                 day = "4";
                 break;
                 case '05':
                 day = "5";
                 break;
                 case '06':
                 day = "6";
                 break;
                 case '07':
                 day = "7";
                 break;
                 case '08':
                 day = "8";
                 break;
                 case '09':
                 day = "9";
                 break;
                 
         }
        switch (month) {
            case '01':
                month = "January";
                break;
            case '02':
                month = "February";
                break;
            case '03':
                month = "March";
                break;
            case '04':
                month = "April";
                break;
            case '05':
                month = "May";
                break;
            case '06':
                month = "June";
                break;
            case '07':
                month = "July";
                break;
            case '08':
                month = "August";
                break;
            case '09':
                month = "September";
                break;
            case '10':
                month = "October";
                break;
            case '11':
                month = "November";
                break;
            case '12':
                month = "December";
                break;
        }
        const date = month + " " + day + "," + " " + year
        return date
    }
}