import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from '../config/api-config';


@Injectable()
export class HomepageService {
    url = API_URL;

    trendingMediaUrl = this.url + 'trending-media?pageNo=';
    feedUrl = this.url + 'trending-feed?pageNo=';
    recentEventsUrl = this.url + 'web-recent-event';
    feedVideoUrl = this.url + 'trending-feed-media';
    nearByEventsUrl = this.url + 'nearby-events?';
    trendingEventVideoUrl = this.url + 'trending-video';
    groupCountsUrl = this.url + 'group-counts';
    upcommingEventsUrl = this.url + 'web-future-event'
    trendingEventUrl = this.url + 'trending-event?pageNo='

    constructor(private apiService: ApiService,
        private http: HttpClient) { }


    getTrendingMedia(page) {
        return this.http.get(this.trendingMediaUrl + page);
    }

    getFeed(page) {
        return this.http.get(this.feedUrl + page);
    }

    getFeedVideo() {
        return this.http.get(this.feedVideoUrl);
    }

    getNearByEvents(lat, long) {
        return this.http.get(this.nearByEventsUrl + 'lat=' + lat + '&long=' + long);
    }

    getTrendingEventVideo() {
        return this.http.get(this.trendingEventVideoUrl);
    }

    getGroupCounts() {
        return this.http.get(this.groupCountsUrl);
    }

    getUpcommingEvents() {
        return this.http.get(this.upcommingEventsUrl)
    }

    getTrendingEvents(page) {
        return this.http.get(this.trendingEventUrl + page);
    }

    getRecentEvents() {
        return this.http.get(this.recentEventsUrl);
    }
}
