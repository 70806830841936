import {Component, Injectable} from '@angular/core';

// Name Service
// tslint:disable-next-line: class-name
export interface myData {
   userId: string;
   password: string;
   googleToken: string;
   isGoogleLogin: boolean;
}



@Injectable()
export class LoginSharedService {
  sharingData: myData = { userId: '', password: '', googleToken: '', isGoogleLogin: false};
  setUserID(str) {
    this.sharingData.userId = str;
  }

  getUserID() {
    return this.sharingData.userId;
  }

  setPassword(str) {
    this.sharingData.password = str;
  }

  getPassword() {
    return this.sharingData.password;
  }

  setGoogleToken(str) {
    this.sharingData.googleToken = str;
  }

  getGoogleToken() {
    return this.sharingData.googleToken;
  }

  setIsGoogleLogin(str) {
    this.sharingData.isGoogleLogin = str;
  }

  getIsGoogleLogin() {
    return this.sharingData.isGoogleLogin;
  }

}
