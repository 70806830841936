import { Injectable, EventEmitter} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';   

// Name Service
// tslint:disable-next-line: class-name
export interface myData {
    pageContext: string;
    searchValue: string;
    searchCityValue: string;
    searchEventTypeValue: string;
    searchGroupTypeValue: string;
}


@Injectable({providedIn: 'root'})
export class CommonService {
  sharingData: myData = { pageContext: 'EVENT', searchValue: '', searchCityValue: '', searchEventTypeValue: '', searchGroupTypeValue: ''};
  invokeFirstComponentFunction = new EventEmitter();
  pageContextFunction = new EventEmitter();
  subsVar: Subscription;
  IsHome: boolean;
  public searchValue: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setPageContext(str) {
    this.sharingData.pageContext = str;
    this.pageContextFunction.emit(str);
  }

  setSearchString(val) {
    this.searchValue.next(val);
  }

  getPageContext() {
    return this.sharingData.pageContext;
  }

  GetIsHomeComponent()
  {
    return this.IsHome;
  }

  SetIsHomeComponent(Val)
  {
    this.IsHome = Val;
  }

  setSearchValue(str) {
    this.sharingData.searchValue = str;
  }

  getSearchValue() {
    return this.sharingData.searchValue;
  }

  setSearchCityValue(str) {
    this.sharingData.searchCityValue = str;
  }

  getSearchCityValue() {
    return this.sharingData.searchCityValue;
  }

  setSearchEventTypeValue(str) {
    this.sharingData.searchEventTypeValue = str;
  }

  getSearchEventTypeValue() {
    return this.sharingData.searchEventTypeValue;
  }

  setSearchGroupTypeValue(str) {
    this.sharingData.searchGroupTypeValue = str;
  }

  getSearchGroupTypeValue() {
    return this.sharingData.searchGroupTypeValue;
  }

  onFirstComponentButtonClick(item) {
    this.invokeFirstComponentFunction.emit(item);
  }

}
