import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

export interface myData {
    eventType: string;
 }

@Injectable()
export class EventService {
  sharingData: myData = { eventType: ''};
  eventFormConfigURL = 'event-form?eventType=';
  saveEventDetailsURL = 'create-event';
  updateEventDetailsURL = 'update-event';
  fetchEventDetailsURL = 'event-detail?eventId=';
  uploadUrl = 'upload-media';
  joinEventUrl = 'join-event';
  sponsorEventUrl = 'sponsor-event';
  partnerEventUrl = 'partner-event';
  signPetitionUrl = 'web-sign-petition';
  getEventMediaURL = 'event-media?eventId=';
  rejectMediaUrl = 'reject-media';
  deleteMediaUrl = 'event-media?eventId=';
  deleteEventUrl = 'event?eventId=';
  blockUserUrl = 'block-user';

  initiateDonationUrl = 'donate';
  recordTransactionUrl = 'donation-status';

  eventsURL = 'events?pageNo=';
  
  eventType = '';
  

  constructor(private apiService: ApiService) {}

  setEventType(str) {
    this.sharingData.eventType = str;
  }

  getEventType() {
    return this.sharingData.eventType;
  }


  getEventFormConfig(userType) {
    return this.apiService.get(this.eventFormConfigURL + userType);
  }

  getEventDetails(eventId) {
    return this.apiService.get(this.fetchEventDetailsURL + eventId);
  }

  saveForm(formData) {
    return this.apiService.post(this.saveEventDetailsURL, formData);
  }

  updateEvent(formData) {
    return this.apiService.post(this.updateEventDetailsURL, formData);
  }

  upload(formData) {
    return this.apiService.post(this.uploadUrl, formData);
  }

  joinEvent(formData) {
    return this.apiService.post(this.joinEventUrl, formData);
  }

  sponsorEvent(formData) {
    return this.apiService.post(this.sponsorEventUrl, formData);
  }

  partnerEvent(formData) {
    return this.apiService.post(this.partnerEventUrl, formData);
  }

  signPetitionEvent(formData) {
    return this.apiService.post(this.signPetitionUrl, formData);
  }

  getEventMedia(eventId) {
    return this.apiService.get(this.getEventMediaURL + eventId);
  }

  getEventMediaWithFilters(eventId, filters) {
    return this.apiService.get(this.getEventMediaURL + eventId + '&filters=' + filters);
  }

  getEvents(page, searchValue, selectedFilters) {
    return this.apiService.get(this.eventsURL + page + '&name=' + searchValue + '&type=' + selectedFilters.toString());
  }

  rejectMedia(obj) {
    return this.apiService.update(this.rejectMediaUrl, obj);
  }

  deleteMedia(obj) {
    return this.apiService.delete(this.deleteMediaUrl + obj.eventId + '&mediaId=' + obj.mediaId);
  }

  deleteEvent(eventId) {
    return this.apiService.delete(this.deleteEventUrl + eventId);
  }

  blockUser(obj) {
    return this.apiService.update(this.blockUserUrl, obj);
  }

  initiateDonation(formData) {
    return this.apiService.post(this.initiateDonationUrl, formData);
  }

  recordTransaction(formData) {
    return this.apiService.post(this.recordTransactionUrl, formData);
  }

}
