import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCaretDown, faShareAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { GroupService } from 'src/app/group/group.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'eq-groups-list',
    templateUrl: 'groups-list.component.html',
    styleUrls: ['groups-list.component.scss']
})

export class GroupListComponent implements OnInit {
    @Input() groups;
    @Output() listUpdated = new EventEmitter();
    faCaretDown = faCaretDown;
    faShareAlt = faShareAlt;
    faUserPlus = faUserPlus;


    constructor(
        private groupService: GroupService,
        private router: Router,
        private toastrService: ToastrService,
        private socialSharing: SocialSharing
    ) {}

    ngOnInit() {
    }

    groupDetails(groupId, users) {
        this.router.navigate(['/group-details/' + groupId]);
    }

}
