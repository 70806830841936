import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/config/api-config';
import { ShareButtonsModule } from '@ngx-share/buttons';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';

import { AgmCoreModule } from '@agm/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPayPalModule } from 'ngx-paypal';

// Importing Services here
import { ApiService } from './services/api.service';
import { CommonService } from './services/common.service';
import { LoginSharedService } from './services/loginDetails.service';
import { LoaderComponent } from './common-component/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader.interceptor';
import { EventSharedService } from './services/eventDetails.service';
// import { MultiStepFormComponent } from './common-component/fields/components/multi-step-form/multi-step-form.component';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GroupService } from './group/group.service';
import { DonationComponent } from './shared/donation/donation.component';
import { SharedPageModule } from './shared/shared.module';
import { HomePageComponent } from './home-page/home-page.component';
import { HeaderComponent } from './common-component/header/header.component';
import { FooterComponent } from './common-component/footer/footer.component';
import { EventService } from './event/event.service';
import { EventDetailsComponent } from './event-details/event-details.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { EventListComponent } from './event-list/event-list.component';
import { GroupListComponent } from './group-list/group-list.component';
import { ShareModule } from '@ngx-share/core';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { PipeModule } from './pipe/pipe.module';
import { PetitionComponent } from './shared/petition/petition.component';





@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    EventDetailsComponent,
    EventListComponent,
    GroupListComponent,
    GroupDetailsComponent,
    LoaderComponent,
    DonationComponent,
    PetitionComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    TermsComponent,
    PolicyComponent,
  ],
  entryComponents: [DonationComponent,PetitionComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: APP_CONFIG.googleConfig.mapAPIKey,
      libraries: ['places']
    }),
    IonicModule.forRoot({hardwareBackButton: false}),
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    ShareModule,
    ShareButtonsModule,
    NgxSpinnerModule,
    SocialLoginModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    SharedPageModule,
    InfiniteScrollModule,
    NgxPayPalModule,
    PipeModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApiService,
    EventService,
    EventSharedService,
    GroupService,
    LoginSharedService,
    LoaderService,
    GooglePlus,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: AuthServiceConfig
    },
    CommonService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
