import {Component, Injectable} from '@angular/core';

// Name Service
// tslint:disable-next-line: class-name
export interface myData {
    eventType: string;
    eventId: string;
    eventAction: string;
    screenTitle: string;
}


@Injectable({providedIn: 'root'})
export class EventSharedService {
  sharingData: myData = { eventType: '', eventId: '', eventAction: 'LIST', screenTitle: 'Events'};
  setEventType(str) {
    this.sharingData.eventType = str;
  }

  getEventType() {
    return this.sharingData.eventType;
  }

  setEventId(str) {
    this.sharingData.eventId = str;
  }

  getEventId() {
    return this.sharingData.eventId;
  }

  setEventAction(str) {
    this.sharingData.eventAction = str;
  }

  getEventAction() {
    return this.sharingData.eventAction;
  }

  setScreenTitle(str) {
    this.sharingData.screenTitle = str;
  }

  getScreenTitle() {
    return this.sharingData.screenTitle;
  }

}
