import { Component, OnInit, Input } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { EventSharedService } from 'src/app/services/eventDetails.service';
import { Router } from '@angular/router';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ShareService } from '@ngx-share/core';

@Component({
  selector: 'eq-social-share',
  templateUrl: 'social-share.component.html',
  styleUrls: ['social-share.component.scss']
})

export class SocialShareComponent implements OnInit {
  @Input() type = 'EVENT';
  @Input() background = 'LIGHT';
  @Input() data;
  @Input() cardType;
  @Input() mediaId;
  @Input() card
  url
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faWhatsapp = faWhatsapp;
  faFacebookMessenger = faFacebookMessenger;
  faMail = faEnvelope;


  constructor(
    public shareService: ShareService,
    private eventSharedService: EventSharedService,
    private router: Router,
    private socialSharing: SocialSharing
  ) { }

  ngOnInit() {
    console.log(this.type,'sc data')
    if (this.type === 'EVENT') {
      this.data['url'] = location.origin + '/event-details/?id=' + this.data?._id;
      console.log(this.data,'data')
    }
    if (this.type === 'GROUP') {
      this.data['url'] = location.origin + '/group-details/' + this.data?._id;
      console.log(this.data,'data')
    }
    if (this.type === 'MEDIA') {
      this.url = location.origin + '/#/event-details/?id=' + this.data + '&media=' + this.mediaId
      console.log(this.url,'data')
    }

  }

  ngOnChanges() {
    if (this.type === 'EVENT') {
      this.data['url'] = location.origin + '/event-details/?id=' + this.data?._id;
      console.log(this.data,'data')
    }
    if (this.type === 'GROUP') {
      this.data['url'] = location.origin + '/group-details/' + this.data?._id;
      console.log(this.data,'data')
    }
    if (this.type === 'MEDIA') {
      this.url = location.origin + '/#/event-details/?id=' + this.data + '&media=' + this.mediaId
      console.log(this.url,'change data')
    }
  }


}
