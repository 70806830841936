// const API_URL = 'http://52.73.130.23:1337/'; // Dev url
// const API_URL = 'http://3.88.241.240:1337/'; // Prod Url
// const API_URL = 'http://localhost:1337/'; // Local Url
// const API_URL = 'http://causechoapi.kruxsoft.in:1338/' //kruxsoft url
// const API_URL = 'http://34.204.138.194:1337/'; // Test Url
const API_URL = 'https://dev-api.causetracker.com/'; // Prod Url
// const API_URL = 'https://service.causecho.com/'; // Test Url



let APP_CONFIG = {
    payPalConfig : {
        devlopment: 'AazIJpEMMRdYag_pEu3UzhYKZRWaPkmLAVLFdF7IhZNL6rm-aLmYRbUChcT6rM3Kltcvd0SYXf4jLsv0',
        production: 'AcJTdX0rKGAlI7Avry2HJOdICga7WsUHNcGNRCGfO3tjRRM5GmdAS8ml2X76PrAjDvVoayTfX2IvMaE0'
    },
    maxUploadFileSize: 15,
    signatureThreshold: 15,
    attendanceThreshold: 15,
    googleConfig : {
        loginClientID: '719791875865-dircroq9chrqlot0m9a7hrgaul062nko.apps.googleusercontent.com',
        mapAPIKey: 'AIzaSyCuD97z_ttw3pYlD3McDzz_ASgse_Fwq30'
    }
};



export { API_URL, APP_CONFIG };
