import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../event/event.service';
import { EventDetailService } from './event-details.service';
import { CommonService } from '../services/common.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  providers: [EventDetailService, NgbCarouselConfig]
})
export class EventDetailsComponent implements OnInit {

  selector: string = '.gallery-panel';
  selectedMediaIndex = 0;
  eventId;
  event;
  eventMedia = [];
  selectedMedia;
  isMediaSelected = false;
  showIndicators = false;
  page = 1;
  closeResult = '';
  stateFromAddress = '';
  startTime
  endTime
  organizer;
  groupDetail;
  mediaId
  closeModalPetition

  constructor(
    private toastrService: ToastrService,
    private eventService: EventDetailService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private config: NgbCarouselConfig) {
    config.interval = 0;
  }

  ngOnInit() {

    this.route.queryParams.subscribe((queryParams) => {
      this.page = 1;
      this.stateFromAddress = "";
      this.eventId = queryParams.id
      this.mediaId = queryParams.media
      this.commonService.setPageContext('EVENT');
      this.eventService.getEventDetails(this.eventId).subscribe(responseInArray => {
        this.event = responseInArray;
        console.log(this.event,'event')
        this.getGroupDetails()
        if (this.event.type != "PETITION") {
          let timeperiod = this.event.additionalInfo.startTime.substring(this.event.additionalInfo.startTime.length - 2)
          if (timeperiod == 'AM' || timeperiod == 'PM') {
            let starttimeperiod = this.event.additionalInfo.startTime.substring(this.event.additionalInfo.startTime.length - 2)
            let starttime = this.event.additionalInfo.startTime.substring(0, this.event.additionalInfo.startTime.length - 3)
            this.startTime = starttime + " " + starttimeperiod
            let endtimeperiod = this.event.additionalInfo.endTime.substring(this.event.additionalInfo.endTime.length - 2)
            let endtime = this.event.additionalInfo.endTime.substring(0, this.event.additionalInfo.endTime.length - 3)
            this.endTime = endtime + " " + endtimeperiod
          }
          else {
            let timezone = this.event.additionalInfo.startTime.substring(this.event.additionalInfo.startTime.length - 3)
            let starttimeperiod = this.event.additionalInfo.startTime.substring(this.event.additionalInfo.startTime.length - 6, this.event.additionalInfo.startTime.length - 4)
            let starttime = this.event.additionalInfo.startTime.substring(0, this.event.additionalInfo.startTime.length - 7)
            this.startTime = starttime + " " + starttimeperiod + " " + timezone
            let endtimeperiod = this.event.additionalInfo.endTime.substring(this.event.additionalInfo.endTime.length - 6, this.event.additionalInfo.endTime.length - 4)
            let endtime = this.event.additionalInfo.endTime.substring(0, this.event.additionalInfo.endTime.length - 7)
            this.endTime = endtime + " " + endtimeperiod + " " + timezone
          }
        }
        this.event['_id'] = this.eventId;
        this.getEventMedia();
        this.stateFromAddress = "";
        if (this.event.type != "PETITION" && !this.event.additionalInfo.onlineEvent) {
          this.getStateFromAddress(this.event);
        }
        else {
          this.getOrganizerAddress(this.event)
        }
      });
    })
  }

  getChange(value){
    if(value['code']['statusCode'] == 200){
      this.eventService.getEventDetails(this.eventId).subscribe(responseInArray => {
        this.event = responseInArray;
      })
    }
  }

  getGroupDetails() {
    this.eventService.getGroupDetails(this.event.createdByGroup).subscribe(responseInArray => {
      this.groupDetail = responseInArray
      this.organizer = responseInArray['name']
    })
  }

  getOrganizerAddress(event) {
    this.eventService.getGroupDetails(event.createdByGroup).subscribe(responseInArray => {
      this.stateFromAddress = responseInArray['city'] + " " + responseInArray["location"]
    })
  }

  getStateFromAddress(event) {
    let tempArr = event?.additionalInfo.eventLocation.formattedAddress.split(',');
    this.stateFromAddress = (tempArr[tempArr.length - 2]);
  }

  getEventMedia() {
    this.eventService.getEventMedia(this.eventId, this.page).subscribe((responseInArray: []) => {
      this.eventMedia = this.eventMedia.concat(responseInArray);
      this.getUrl()
    });
  }

  getUrl() {
    if(this.mediaId != undefined){
      this.selectedMediaFunction(this.mediaId)
      document.getElementById("media").scrollIntoView()
    }
  }
  // selectedMediaFunction(media, index) {
  //   console.log(index,'in')
  //   this.selectedMedia = this.eventMedia[index];
  //   this.isMediaSelected = true;
  // }

  selectedMediaFunction(id) {
    for (let i = 0; i < this.eventMedia.length; i++) {
      if (this.eventMedia[i]._id === id) {
        this.selectedMedia = this.eventMedia[i]
        console.log(this.selectedMedia,'media')
        this.selectedMediaIndex = i
        this.isMediaSelected = true;
        break;
      }
    }
  }

  onScrollMediaList() {
    this.page++;
    this.getEventMedia();
  }

  goToGroup() {
    this.router.navigate(['/group-details/' + this.groupDetail._id]);
  }

  donateToEvent(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  signPetition(petition) {
    this.modalService.open(petition, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // signPetittion() {
  //   let obj = {
  //     eventId: this.eventId
  //   }
  //   this.eventService.signPetitionEvent(obj).subscribe((response) => {
  //     if (response['code']['statusCode'] == 400) {
  //       this.toastrService.error("Petition Already Signed")
  //     }
  //     if (response['code']['statusCode'] == 200) {
  //       this.toastrService.success('Signed Petition Successfully')
  //     }
  //   })
  // }

  joinVirtualEvent() {
    window.open(this.event.additionalInfo.eventLink, '_system', 'location = yes')
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openGallery(mediaDetails) {
    this.selectedMediaIndex = this.eventMedia.findIndex(details => details._id === this.selectedMedia._id);
    this.modalService.open(mediaDetails, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  slideChanged(event) {
    if (event.direction === 'left') {
      this.selectedMediaIndex = this.selectedMediaIndex + 1;
    }
    if (event.direction === 'right') {
      this.selectedMediaIndex = this.selectedMediaIndex - 1;
    }
    this.selectedMedia = this.eventMedia[this.selectedMediaIndex];
  }

}
