import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeformat'
})
export class TimeFormatPipe implements PipeTransform {
    transform(title: string): string {
        let h = Number(title.substring(11,13))
        var hr:number = h + 5
        let m = Number(title.substring(14,16))
        var min:number = m + 30
        var timezone:string = "AM"
        var time:string
        if(min > 60){
            min = min - 60
            hr = hr + 1
        }
        if(hr > 12 && hr < 24){
            hr = hr-12  
            timezone = "PM"
        }
        if(hr >= 24){
            hr = hr -24
            timezone = "AM"
        }
        if(hr == 12){
            timezone = "PM"
        }
        if(hr < 10 && min >= 10){
            time = hr + ":" + min + " " + timezone
        }
        else if(hr < 10 && min < 10){
            time =  hr + ":" + "0" + min + " " + timezone
        }
        else if(hr >= 10 && min < 10){
            time =  hr + ":" + "0" + min + " " + timezone
        }
        else{
            time =  hr + ":" + min + " " + timezone
        }
        return time
    }
}