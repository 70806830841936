import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from '../config/api-config';


@Injectable()
export class GroupDetailService {
    url = API_URL;

    fetchGroupDetailsURL =    this.url + 'web-group-detail?groupId=';
    fetchGroupMediaURL =    this.url + 'web-group-media?groupId=';
    fetchUpcomingEventsURL =    this.url + 'web-group-future-event?groupId=';
    fetchPastEventsURL =    this.url + 'web-group-past-event?groupId=';
    fetchCurrentEventsURL = this.url + 'web-group-current-event?groupId='

    constructor(private apiService: ApiService,
                private http: HttpClient) {}


    getGroupDetails(groupId) {
        return this.http.get(this.fetchGroupDetailsURL + groupId);
    }

    getGroupMedia(groupId, page) {
        return this.http.get(this.fetchGroupMediaURL + groupId + '&pageNo=' + page);
    }

    getUpcomingEvents(groupId, page) {
        return this.http.get(this.fetchUpcomingEventsURL + groupId + '&pageNo=' + page);
    }

    getPastEvents(groupId, page) {
        return this.http.get(this.fetchPastEventsURL + groupId + '&pageNo=' + page);
    }

    getCurrentEvents(groupId, page){
        return this.http.get(this.fetchCurrentEventsURL + groupId + '&pageNo=' + page)
    }
}
