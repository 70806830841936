import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { DonationService } from './donation.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { APP_CONFIG } from 'src/app/config/api-config';
import { EmailValidator } from 'src/app/validators';


@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss'],
  providers: [DonationService]
})
export class DonationComponent implements OnInit {
  public payPalConfig ? : IPayPalConfig;
  @Output() closeModal = new EventEmitter();
  @Input() entityName;
  @Input() entityType = 'EVENT';
  @Input() groupId;
  @Input() eventId;
  name = '';
  email = '';
  amount = '';
  description = '';
  initiateDonationResponse;
  isEmailValid = false;
  clickedSubmit = false;
  constructor(
              private toastrService: ToastrService,
              private donationService: DonationService,
            ) { }

  ngOnInit() {
    this.name = '';
    this.email = '';
    this.amount = '';
    if (this.entityType === 'GROUP') {
      this.donateToGroup();
    } else {
      this.donateToEvent();
    }
  }

  donateToGroup() {
    this.payPalConfig = {
      currency: 'USD',
      clientId: APP_CONFIG.payPalConfig.production,
      // tslint:disable-next-line: no-angle-bracket-type-assertion
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: this.amount,
            },
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        let recordTransactionObj = {
          transactionId: this.initiateDonationResponse['data']['transactionId'],
          status: 'DONE',
          paypalObj: data
        };
        this.callRecordGroupTransaction(recordTransactionObj);
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
        let recordTransactionObj = {
          transactionId: this.initiateDonationResponse['data']['transactionId'],
          status: 'FAILED',
          paypalObj: data
        };
        this.callRecordGroupTransaction(recordTransactionObj);
      },
      onError: err => {
        // console.log('OnError', err);
        let recordTransactionObj = {
          transactionId: this.initiateDonationResponse['data']['transactionId'],
          status: 'FAILED',
          paypalObj: err
        };
        this.callRecordGroupTransaction(recordTransactionObj);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
        let initiateDonationObj = {
          donorEmail: this.email,
          donorName: this.name,
          amount: this.amount,
          currency: 'USD',
          groupId: this.groupId
        };
        this.initiateGroupDonation(initiateDonationObj);
      },
    };
  }

  donateToEvent() {
    this.payPalConfig = {
      currency: 'USD',
      clientId: APP_CONFIG.payPalConfig.production,
      // tslint:disable-next-line: no-angle-bracket-type-assertion
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: this.amount,
            },
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        let recordTransactionObj = {
          transactionId: this.initiateDonationResponse['data']['transactionId'],
          status: 'DONE',
          paypalObj: data
        };
        this.callRecordEventTransaction(recordTransactionObj);
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
        let recordTransactionObj = {
          transactionId: this.initiateDonationResponse['data']['transactionId'],
          status: 'FAILED',
          paypalObj: data
        };
        this.callRecordEventTransaction(recordTransactionObj);
      },
      onError: err => {
        // console.log('OnError', err);
        let recordTransactionObj = {
          transactionId: this.initiateDonationResponse['data']['transactionId'],
          status: 'FAILED',
          paypalObj: err
        };
        this.callRecordEventTransaction(recordTransactionObj);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
        let initiateDonationObj = {
          donorEmail: this.email,
          donorName: this.name,
          amount: this.amount,
          currency: 'USD',
          groupId: this.groupId,
          eventId: this.eventId
        };
        this.initiateEventDonation(initiateDonationObj);
      },
    };
  }

  initiateGroupDonation(initiateDonationObj) {
    this.donationService.initiateGroupDonation(initiateDonationObj).subscribe( initiateDonationResponse => {
      this.initiateDonationResponse = initiateDonationResponse;
    });
  }

  initiateEventDonation(initiateDonationObj) {
    this.donationService.initiateEventDonation(initiateDonationObj).subscribe( initiateDonationResponse => {
      this.initiateDonationResponse = initiateDonationResponse;
    });
  }

  callRecordGroupTransaction(recordTransactionObj) {
    this.clickedSubmit = false;
    this.donationService.recordGroupTransaction(recordTransactionObj).subscribe( recordTransactionResponse => {
      if (recordTransactionObj['status'] === 'FAILED') {
        this.toastrService.error('Transaction Unsuccessful!!', '', {
          disableTimeOut: true
        });
      } else {
        this.toastrService.success('Transaction Successful');
        this.closeModal.emit(null);
      }
    });
  }

  callRecordEventTransaction(recordTransactionObj) {
    this.clickedSubmit = false;
    this.donationService.recordEventTransaction(recordTransactionObj).subscribe( recordTransactionResponse => {
      if (recordTransactionObj['status'] === 'FAILED') {
        this.toastrService.error('Transaction Unsuccessful!!', '', {
          disableTimeOut: true
        });
      } else {
        this.toastrService.success('Transaction Successful');
        this.closeModal.emit(null);
      }
    });
  }

  
  emailValidate(c) {
    this.isEmailValid = EmailValidator.validate(c);
  }

}
