import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private titleService: Title,private router: Router,private commonService: CommonService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.titleService.setTitle('CauseTracker');
    if(this.router.url == '/home' || this.router.url == '/')
    {
      this.commonService.SetIsHomeComponent(true);
    }
    else
    this.commonService.SetIsHomeComponent(false);
  }
  
  changeOfRoutes()
  {
    if(this.router.url == '/home' || this.router.url == '/')
    {
      this.commonService.SetIsHomeComponent(true);
    }
    else
    this.commonService.SetIsHomeComponent(false);
  }
}
