import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { EventSharedService } from 'src/app/services/eventDetails.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'eq-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss']
})

export class SearchComponent implements OnInit {
    // @Input() users;
    @Output() searchedItem = new EventEmitter();

    faSearch = faSearch;

    searchString;
    currentUser;
    unitsubscription

    constructor(
        private eventSharedService: EventSharedService,
        private router: Router,
        private modalController: ModalController,
        private commonService: CommonService
    ) {}

    ngOnInit() {
        this.unitsubscription = this.commonService.searchValue.subscribe(search => {
            this.searchString = search;
        })
    }

    ngOnDestroy(){
        this.unitsubscription.unsubscribe()
    }

    ionViewDidEnter() {

    }

    searchElement() {
	this.searchedItem.emit(this.searchString);
	this.commonService.setSearchString(this.searchString)
	/*if( this.searchString !== '' ){
            this.searchedItem.emit(this.searchString);
            this.commonService.setSearchString(this.searchString)
        } else {
            this.router.navigate(['/home']);
	}*/
    }

}
