import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faCaretDown, faHandsHelping, faShareAlt, faUpload, faDonate, faDollarSign,
  faHandshake, faFileContract, faCamera
} from '@fortawesome/free-solid-svg-icons';
import { EventSharedService } from 'src/app/services/eventDetails.service';
import { Router } from '@angular/router';

import { ModalController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/event/event.service';

import { DonationComponent } from '../donation/donation.component';

@Component({
  selector: 'eq-events-list',
  templateUrl: 'events-list.component.html',
  styleUrls: ['events-list.component.scss']
})

export class EventsListComponent implements OnInit {
  @Input() events;
  @Input() cardType = "TYPE1";
  @Output() listUpdated = new EventEmitter();
  faCaretDown = faCaretDown;
  faShareAlt = faShareAlt;
  faUpload = faUpload;
  faDonate = faDonate;
  faDollarSign = faDollarSign;
  faHandshake = faHandshake;
  faHandsHelping = faHandsHelping;
  faFileContract = faFileContract;
  faCamera = faCamera;

  otherGroupUser = false;
  userType;


  constructor(
    private eventService: EventService,
    public eventSharedService: EventSharedService,
    public router: Router,
    public modalController: ModalController,
    public ngxSpinnerService: NgxSpinnerService,
    public toastrService: ToastrService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    console.log(this.events,'list of events')
  }
  eventDetails(eventId, eventType) {
    this.router.navigate(['/event-details'], {queryParams:{id:eventId}});
  }



  joinEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.joinEvent(obj).subscribe(response => {
      this.toastrService.success('Joined Event Successfully');
      this.listUpdated.emit(null);
    });
  }

  sponsorEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.sponsorEvent(obj).subscribe(response => {
      this.toastrService.success('Request to Sponsor Event Sent Successfully');
      this.listUpdated.emit(null);
    });
  }

  partnerEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.partnerEvent(obj).subscribe(response => {
      this.toastrService.success('Request to  Partner Event Sent Successfully');
      this.listUpdated.emit(null);
    });
  }

  signPetitionEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.signPetitionEvent(obj).subscribe(response => {
      this.toastrService.success('Signed Petition Successfully');
      this.listUpdated.emit(null);
    });
  }

  async donateToEvent(eventID, eventNM, createdByGroup) {
    const modalDonation = await this.modalController.create({
      component: DonationComponent,
      componentProps: { eventId: eventID, entitytName: eventNM, ownerGroupId: createdByGroup }
    });
    return await modalDonation.present();
  }

  checkIsLive(endDate, endTime, startDate, startTime) {
    var d = new Date();
    var CurrentDate = d.getDate()
    var CurrentMonth = (d.getMonth() + 1)
    var CurrentYear = d.getFullYear();
    var SD = new Date(startDate).getDate();
    var SM = (new Date(startDate).getMonth() + 1);
    var SY = new Date(startDate).getFullYear();
    var ED = new Date(endDate).getDate();
    var EM = (new Date(endDate).getMonth() + 1);
    var EY = new Date(endDate).getFullYear();
    if (SD < CurrentDate && ED > CurrentDate) {
      if ((SM <= CurrentMonth && EM >= CurrentMonth) && (SY <= CurrentYear && EY >= CurrentYear)) { return true; }
    }
    else if (ED == CurrentDate) {
      var CurrentTime = [];
      var StartTime = startTime.split(":")
      var EndTime = endTime.split(":");
      CurrentTime[0] = d.getHours();

      CurrentTime[1] = d.getMinutes();

      if (StartTime[2] == 'PM') {
        StartTime[0] = 12 + (StartTime[0] % 12);
      }
      else if (StartTime[0] == '12') {
        StartTime[0] = 0;
      }

      if (EndTime[2] == 'PM') {
        EndTime[0] = 12 + (EndTime[0] % 12);
      }
      else if (EndTime[0] == '12') {
        EndTime[0] = 0;
      }

      if (CurrentTime[0] < EndTime[0]) {
        if ((SM <= CurrentMonth && EM >= CurrentMonth) && (SY <= CurrentYear && EY >= CurrentYear))
          return true
      }
      else
        if (CurrentTime[0] == EndTime[0]) {
          if (CurrentTime[1] <= EndTime[1]) {
            if ((SM <= CurrentMonth && EM >= CurrentMonth) && (SY <= CurrentYear && EY >= CurrentYear))
              return true;
          }
          else
            return false;
        }
        else
          return false;
    }

    else if (SD == CurrentDate) {
      var CurrentTime = [];
      var StartTime = startTime.split(":")
      var EndTime = endTime.split(":");
      CurrentTime[0] = d.getHours();

      CurrentTime[1] = d.getMinutes();

      if (StartTime[2] == 'PM') {
        StartTime[0] = 12 + (StartTime[0] % 12);
      }
      else if (StartTime[0] == '12') {
        StartTime[0] = 0;
      }

      if (EndTime[2] == 'PM') {
        EndTime[0] = 12 + (EndTime[0] % 12);
      }
      else if (EndTime[0] == '12') {
        EndTime[0] = 0;
      }

      if (CurrentTime[0] > StartTime[0]) {
        if ((SM <= CurrentMonth && EM >= CurrentMonth) && (SY <= CurrentYear && EY >= CurrentYear))
          return true
      }
      else
        if (CurrentTime[0] == StartTime[0]) {
          if (CurrentTime[1] >= StartTime[1]) {
            if ((SM <= CurrentMonth && EM >= CurrentMonth) && (SY <= CurrentYear && EY >= CurrentYear))
              return true;
          }
          else
            return false;
        }
        else
          return false;
    }
  }

}
