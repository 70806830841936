import { Component, OnInit } from '@angular/core';
import { GroupListService } from './group-list.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
  providers: [GroupListService]
})
export class GroupListComponent implements OnInit {

  searchsubscription
  selector: string = '.groups-panel';
  page;
  groups = [];
  searchValue = '';
  searchCityValue = '';
  searchGroupTypeValue = '';
  selectedFilters = [];
  filters = [{
    code: 'CIVIC',
    name: 'Civic'
  },
  {
    code: 'RELIGIOUS',
    name: 'Religious'
  },
  {
    code: 'SOCIAL',
    name: 'Social'
  },
  {
    code: 'CHARITABLE',
    name: 'Charitable'
  },
  {
    code: 'OTHER',
    name: 'Other'
  }];
  constructor(
    private groupListService: GroupListService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.page = 1;
    this.groups = [];
    this.commonService.setPageContext('GROUP');
    // this.searchValue = this.commonService.getSearchValue() ? this.commonService.getSearchValue() : '';
    this.searchsubscription = this.commonService.searchValue.subscribe(search => {
      this.searchValue = search
      this.searchCityValue = this.commonService.getSearchCityValue() ? this.commonService.getSearchCityValue() : '';
      this.searchGroupTypeValue = this.commonService.getSearchGroupTypeValue();
      this.selectedFilters = [this.commonService.getSearchGroupTypeValue()];
      this.getGroupsList();

      // Checking for search value
      if (this.commonService.subsVar === undefined) {
        this.commonService.subsVar = this.commonService.
          invokeFirstComponentFunction.subscribe((item: string) => {
            this.page = 1;
            this.groups = [];
            this.searchValue = item;
            this.getGroupsList();
          });
      }
    })
  }

  ngOnDestroy() {
    this.commonService.setSearchString('')
    this.searchsubscription.unsubscribe();
  }

  getGroupsList() {
    this.page = 1
    this.groupListService.getGroupsList(this.page, this.searchValue, this.searchCityValue, this.selectedFilters)
      .subscribe((groups: []) => {
        // this.commonService.setSearchValue('');
        // this.commonService.setSearchCityValue('');
        // this.searchValue = '';
        // this.searchCityValue = '';
        // this.groups = this.groups.concat(groups);
        this.groups = groups
        console.log(this.groups,'group')
      });
  }

  getScrollGroup() {
    this.groupListService.getGroupsList(this.page, this.searchValue, this.searchCityValue, this.selectedFilters)
      .subscribe((groups: []) => {
        this.groups = this.groups.concat(groups)
        console.log(this.groups,'scroll group')
      })
  }

  onScrollGroups() {
    this.page++;
    this.getScrollGroup();
  }

  selectedFilter(filters) {
    this.page = 1;
    this.groups = [];
    this.selectedFilters = filters;
    this.getGroupsList();
  }

  loadGroups() {

  }

}
