import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventSharedService } from 'src/app/services/eventDetails.service';
import { Router } from '@angular/router';

import { ModalController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/event/event.service';

import { DonationComponent } from '../donation/donation.component';

@Component({
  selector: 'eq-media-list',
  templateUrl: 'media-list.component.html',
  styleUrls: ['media-list.component.scss']
})

export class MediaListComponent implements OnInit {
  @Input() media;
  @Output() listUpdated = new EventEmitter();


  otherGroupUser = false;
  userType;


  constructor(
    private eventService: EventService,
    public eventSharedService: EventSharedService,
    public router: Router,
    public modalController: ModalController,
    public ngxSpinnerService: NgxSpinnerService,
    public toastrService: ToastrService
  ) { }

  ngOnInit() {
  }

  eventDetails(eventId, eventType) {
    this.router.navigate(['/event-details/?id=' + eventId]);
  }

  joinEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.joinEvent(obj).subscribe(response => {
      this.toastrService.success('Joined Event Successfully');
      this.listUpdated.emit(null);
    });
  }

  sponsorEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.sponsorEvent(obj).subscribe(response => {
      this.toastrService.success('Request to Sponsor Event Sent Successfully');
      this.listUpdated.emit(null);
    });
  }

  partnerEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.partnerEvent(obj).subscribe(response => {
      this.toastrService.success('Request to  Partner Event Sent Successfully');
      this.listUpdated.emit(null);
    });
  }

  signPetitionEvent(eventID) {
    let obj = {
      eventId: eventID
    }
    this.eventService.signPetitionEvent(obj).subscribe(response => {
      this.toastrService.success('Signed Petition Successfully');
      this.listUpdated.emit(null);
    });
  }

  async donateToEvent(eventID, eventNM, createdByGroup) {
    const modalDonation = await this.modalController.create({
      component: DonationComponent,
      componentProps: { eventId: eventID, entitytName: eventNM, ownerGroupId: createdByGroup }
    });
    return await modalDonation.present();
  }



}
