import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from '../config/api-config';


@Injectable()
export class EventListService {
    url = API_URL;

    fetchEventListURL =    this.url + 'web-events?pageNo=';

    constructor(private apiService: ApiService,
                private http: HttpClient) {}


    getEventList(page, searchValue, city, selectedTypeFilters) {
        return this.http.get(this.fetchEventListURL + page + '&search=' + searchValue + '&city=' + city + '&type=' + selectedTypeFilters.toString());
    }

}
