import { Component, OnInit } from '@angular/core';
import { EventListService } from './event-list.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  providers: [EventListService]
})
export class EventListComponent implements OnInit {
  searchsubscription
  selector: string = '.events-panel';
  page;
  events = [];
  searchValue = '';
  searchCityValue = '';
  searchEvenTypeValue = '';
  selectedFilters = [];
  filters = [{
    code: 'FUNDRAISER',
    name: 'Fundraiser'
  },
  {
    code: 'RALLY',
    name: 'Rally'
  },
  {
    code: 'DEMONSTRATION',
    name: 'Demonstration'
  },
  {
    code: 'DISASTERRELIEF',
    name: 'Humanitarian Aid'
  },
  {
    code: 'PETITION',
    name: 'Petition'
  }];
  constructor(
    private eventListService: EventListService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.page = 1;
    this.commonService.setPageContext('EVENT');
      // this.searchValue = this.commonService.getSearchValue() ? this.commonService.getSearchValue() : '';
    this.searchsubscription = this.commonService.searchValue.subscribe(search => {
      this.searchValue = search;
      this.searchCityValue = this.commonService.getSearchCityValue() ? this.commonService.getSearchCityValue() : '';
      this.searchEvenTypeValue = this.commonService.getSearchEventTypeValue();
      this.selectedFilters = [this.commonService.getSearchEventTypeValue()];
      this.getEventsList();
      
      // Checking for search value
      if (this.commonService.subsVar === undefined) {
        this.commonService.subsVar = this.commonService.
          invokeFirstComponentFunction.subscribe((item: string) => {
            this.page = 1;
            this.events = [];
            this.searchValue = item;
            this.getEventsList();
          });
      }
    });

  }

  ngOnDestroy() {
    this.commonService.setSearchString('')
    this.searchsubscription.unsubscribe();
  }

  getEventsList() {
    // this.events = []
    this.page = 1
    this.eventListService.getEventList(this.page, this.searchValue, this.searchCityValue, this.selectedFilters)
      .subscribe((events: []) => {
        // this.commonService.setSearchValue('');
        // this.commonService.setSearchCityValue('');
        // this.searchValue = '';
        // this.searchCityValue = '';
        this.events = events
        // this.events = this.events.concat(events);
      });
  }

  loadEvents() {

  }

  getScrollEvents() {
    this.eventListService.getEventList(this.page, this.searchValue, this.searchCityValue, this.selectedFilters)
      .subscribe((events: []) => {
        // this.commonService.setSearchValue('');
        // this.commonService.setSearchCityValue('');
        // this.searchValue = '';
        // this.searchCityValue = '';
        this.events = this.events.concat(events);
      });
  }

  onScrollEvents() {
    this.page++;
    this.getScrollEvents();
  }

  selectedFilter(filters) {
    this.page = 1;
    this.events = [];
    this.selectedFilters = filters;
    this.getEventsList();
  }

  search(event) {
  }

}
