import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { EventSharedService } from 'src/app/services/eventDetails.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'eq-filters',
    templateUrl: 'filters.component.html',
    styleUrls: ['filters.component.scss']
})

export class FiltersComponent implements OnInit {
    @Input() receivedFilter;
    @Input() filters;
    @Output() selectedFilter = new EventEmitter();

    faSearch = faSearch;

    // searchString;
    currentUser;
    selectedFiltersArray = [];


    constructor(
        private eventSharedService: EventSharedService,
        private router: Router,
        private modalController: ModalController,
    ) {}

    ngOnInit() {
        this.currentUser = localStorage.getItem('userId');
        console.log(this.receivedFilter,'imp')
        // if (this.receivedFilter != null) {
        //     this.selectedFiltersArray.push(this.receivedFilter);
        // }
    }

    ionViewDidEnter() {

    }

    clickedFilter(filter) {
        console.log(filter,'filter')
        // this.searchString = filter;
        if (this.selectedFiltersArray.includes(filter)) {
            let index = this.selectedFiltersArray.indexOf(filter);
            this.selectedFiltersArray.splice(index, 1);
        } else {
            this.selectedFiltersArray.push(filter);
        }
        this.selectedFilter.emit(this.selectedFiltersArray);
    }

    checkSelectedFilter(filter) {
        return this.selectedFiltersArray.includes(filter);
    }

}
