import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ShareButtonModule } from '@ngx-share/button';

import { IonicModule } from '@ionic/angular';

import { SharedPageRoutingModule } from './shared-routing.module';

import { SharedPage } from './shared.page';
import { FloatingButtonComponent } from './floating-button/floating-button.component';
import { GroupListComponent } from './groups-list/groups-list.component';
import { EventsListComponent } from './events-list/events-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SocialShareComponent } from './social-share/social-share.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SearchComponent } from './search/search.component';
import { FiltersComponent } from './filters/filters.component';
import { DonationComponent } from './donation/donation.component';
import { MediaListComponent } from './media-list/media-list.component';
import { PipeModule } from '../pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedPageRoutingModule,
    FontAwesomeModule,
    ShareButtonModule,
    PipeModule
  ],
  declarations: [
    SharedPage,
    FloatingButtonComponent,
    EventsListComponent,
    GroupListComponent,
    SocialShareComponent,
    SearchComponent,
    FiltersComponent,
    MediaListComponent
    // DonationComponent
  ],
  exports: [
    FloatingButtonComponent,
    EventsListComponent,
    GroupListComponent,
    SocialShareComponent,
    SearchComponent,
    FiltersComponent,
    MediaListComponent
    // DonationComponent
  ],
  providers: [SocialSharing]
})
export class SharedPageModule {}
