import { Component, OnInit } from '@angular/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {

  faEnvelope = faEnvelope;
  faPhone = faPhone;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.setPageContext('ABOUT');
  }

  routeToDownloadAndroid(){
    window.open('https://play.google.com/store/apps/details?id=com.causechoapp')
  }

  routeToDownloadIOS(){
    window.open('https://play.google.com/store/apps/details?id=com.causechoapp')
  }
  
}
