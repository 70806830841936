import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/event/event.service';
import { PetitionService } from './petition.service';

@Component({
  selector: 'app-petition',
  templateUrl: './petition.component.html',
  styleUrls: ['./petition.component.scss'],
  providers: [PetitionService]
})
export class PetitionComponent implements OnInit {

  @Input() eventId;
  @Output() closeModalPetition = new EventEmitter();
  @Output() response = new EventEmitter()
  email
  name

  constructor(
    private eventService: EventService,
    private toasterService: ToastrService
  ) {
  }

  ngOnInit() { }

  signPetition() {
    if (this.name == undefined || this.name == '')
      this.toasterService.error("Enter Name")
    else if (this.email == undefined || this.email == '')
      this.toasterService.error("Enter Email")
    else {
      let obj = {
        eventId: this.eventId,
        email: this.email,
        name: this.name
      }
      this.eventService.signPetitionEvent(obj).subscribe((response) => {
        if (response['code']['statusCode'] == 200) {
          this.toasterService.error("Signed Petition Successfully")
        }
        if (response['code']['statusCode'] == 400) {
          this.toasterService.error("Petition Already Signed")
        }
        this.response.emit(response)
        this.closeModalPetition.emit(null)
      })
    }
  }

  cancel() {
    this.closeModalPetition.emit(null)
  }
}
