import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

export interface myData {
    mode: string;
    groupId: string;
}

@Injectable({ providedIn: 'root'})
export class GroupService {
    sharingData: myData = { mode: 'PUBLIC', groupId: ''};
    groupsURL = 'groups?pageNo=';
    updateGroupDetailsURL = 'update-event';
    fetchGroupDetailsURL = 'event-detail?eventId=';
    followGroupUrl = 'follow-group';
    signPetitionUrl = 'sign-petition';
    getMediaURL = 'event-media?eventId=';

    constructor(private apiService: ApiService) {}

    setMode(str) {
        this.sharingData.mode = str;
    }

    getMode() {
        return this.sharingData.mode;
    }

    setGroupId(str) {
        this.sharingData.groupId = str;
    }

    getGroupId() {
        return this.sharingData.groupId;
    }

    getGroups(page, searchValue, selectedFilters) {
        return this.apiService.get(this.groupsURL + page + '&name=' + searchValue + '&groupType=' + selectedFilters.toString());
    }

    getGroupDetails(groupId) {
        return this.apiService.get(this.fetchGroupDetailsURL + groupId);
    }

    updateGroup(formData) {
        return this.apiService.post(this.updateGroupDetailsURL, formData);
    }

    followGroup(formData) {
        return this.apiService.post(this.followGroupUrl, formData);
    }

    getMedia(mediaId) {
        return this.apiService.get(this.getMediaURL + mediaId);
    }

}
