import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from '../config/api-config';


@Injectable()
export class EventDetailService {
    url = API_URL;

    fetchEventDetailsURL = this.url + 'web-event-detail?eventId=';
    fetchEventMediaURL = this.url + 'web-event-media?eventId=';
    // signPetitionUrl = 'sign-petition';
    fetchGroupDetailFromEvent = 'group-detail?id='
    fetchGroupDetailsURL =    this.url + 'web-group-detail?groupId=';
    signPetitionUrl = this.url + 'web-sign-petition';

    constructor(private apiService: ApiService,
        private http: HttpClient) { }


    getEventDetails(eventId) {
        return this.http.get(this.fetchEventDetailsURL + eventId);
    }

    getEventMedia(eventId, page) {
        return this.http.get(this.fetchEventMediaURL + eventId + '&pageNo=' + page);
    }

    signPetitionEvent(formData) {
        return this.apiService.post(this.signPetitionUrl, formData);
    }
    getGroupDetailFromEvent(eventId){
        return this.apiService.get(this.fetchGroupDetailFromEvent + eventId)
    }

    getGroupDetails(groupId) {
        return this.http.get(this.fetchGroupDetailsURL + groupId);
    }

}
