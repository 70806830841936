import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { EventListComponent } from './event-list/event-list.component';
import { GroupListComponent } from './group-list/group-list.component';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent},
  { path: 'event-details/:id', component: EventDetailsComponent},
  { path: 'event-details', component: EventDetailsComponent},
  { path: 'events', component: EventListComponent},
  { path: 'group-details/:id', component: GroupDetailsComponent},
  { path: 'groups', component: GroupListComponent},
  { path: 'about', component: AboutComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'policy', component: PolicyComponent},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
