import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeformatter'
})
export class TimeFormatterPipe implements PipeTransform {
    transform(title: string): string {
        var hour = title.split(':')[0].trim();
        switch (hour) {
            case '01':
                hour = "1";
                break;
            case '02':
                hour = "2";
                break;
            case '03':
                hour = "3";
                break;
            case '04':
                hour = "4";
                break;
            case '05':
                hour = "5";
                break;
            case '06':
                hour = "6";
                break;
            case '07':
                hour = "7";
                break;
            case '08':
                hour = "8";
                break;
            case '09':
                hour = "9";
                break;
        }
        if(title.substring(5,6) == ':'){
            var minute = title.substring(2,5)
            var rest = title.split(':').pop()
            const time = hour + minute + ' ' + rest
            return time
        }
        else{
            var rest = title.substring(title.indexOf(':'))
            const time = hour + rest
            return time
        }
    }
}
