import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from '../date-format.pipe';
import {TimeFormatPipe} from './time-format.pipe'
import { TimeFormatterPipe } from '../time-format.pipe';


@NgModule({
  declarations: [DateFormatPipe,TimeFormatPipe,TimeFormatterPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[DateFormatPipe,TimeFormatPipe,TimeFormatterPipe]
})
export class PipeModule { }
