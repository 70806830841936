import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from '../config/api-config';
import { EncodingType } from '@ionic-native/camera/ngx';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  url = API_URL;

  constructor(public http: HttpClient) {}

  get(uri: string) {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('userId');
    let options = {
      headers: new HttpHeaders().append('Authorization', 'bearer ' + token)
                                .append('Content-Type', 'application/json')
                                .append('loggedinuser', userEmail)
    };
    return this.http.get(this.url + uri, options);
  }

  post(uri: string, model: any) {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('userId');
    let options = {
      headers: new HttpHeaders().append('Authorization', 'bearer ' + token)
                                .append('Content-Type', 'application/json')
                                .append('loggedinuser', userEmail)
    };
    return this.http.post(this.url + uri, model, options);
  }

  upload(uri: string, model: any) {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('userId');
    let options = {
      headers: new HttpHeaders().append('Authorization', 'bearer ' + token)
                                .append('loggedinuser', userEmail)
                                .append('enctype', 'multipart/form-data;')
    };
    return this.http.post(this.url + uri, model, options);
  }

  update(uri: string, model: any) {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('userId');
    let options = {
      headers: new HttpHeaders().append('Authorization', 'bearer ' + token)
                                .append('Content-Type', 'application/json')
                                .append('loggedinuser', userEmail)
    };
    return this.http.put(this.url + uri, model, options);
  }

  delete(uri: string) {
    let token = localStorage.getItem('token');
    let userEmail = localStorage.getItem('userId');
    let options = {
      headers: new HttpHeaders().append('Authorization', 'bearer ' + token)
                                .append('Content-Type', 'application/json')
                                .append('loggedinuser', userEmail)
    };
    return this.http.delete(this.url + uri, options);
  }
}
