import { Component, OnInit } from '@angular/core';
import { faPlus, faUsers, faFileContract,
        faHandHoldingHeart, faChalkboardTeacher,
        faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { EventSharedService } from 'src/app/services/eventDetails.service';
import { Router } from '@angular/router';

@Component({
    selector: 'eq-floating-button',
    templateUrl: 'floating-button.component.html',
    styleUrls: ['floating-button.component.scss']
})

export class FloatingButtonComponent implements OnInit {
    faPlus = faPlus;
    faUsers = faUsers;
    faFileContract = faFileContract;
    faHandHoldingHeart = faHandHoldingHeart;
    faChalkboardTeacher = faChalkboardTeacher;
    faHandHoldingUsd = faHandHoldingUsd;
    constructor(
        private eventSharedService: EventSharedService,
        private router: Router
    ) {}

    ngOnInit() {}

    goToEvent(eventype) {
        this.eventSharedService.setEventAction('CREATE');
        localStorage.setItem('eventType', eventype);
        this.router.navigate(['/app/action/events/create']);
    }
}
