import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GroupDetailService } from './group-details.service';
import { CommonService } from '../services/common.service';
import { APP_CONFIG } from '../config/api-config';
import { DonationComponent } from '../shared/donation/donation.component';


@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
  providers: [GroupDetailService]
})
export class GroupDetailsComponent implements OnInit {
  selector: string = '.list-panel';
  modalReference: NgbModalRef;
  // media = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25];
  
  closeResult = '';
  groupId;
  group;
  page = 1;
  pageUpcoming = 1;
  pagePast = 1;
  pageCurrent = 1;
  groupMedia = [];
  events = [];
  event = [];
  upcomingEvents = [];
  pastEvents = [];
  currentEvents = [];
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private groupDetailService: GroupDetailService
  ) { }

  ngOnInit() {

    this.page = 1;
    this.pageUpcoming = 1;
    this.pagePast = 1;
    this.pageCurrent = 1;
    this.upcomingEvents = [];
    this.pastEvents = [];
    this.currentEvents = [];

    this.groupId = this.route.snapshot.paramMap.get('id');
    this.commonService.setPageContext('GROUP');
    this.groupDetailService.getGroupDetails(this.groupId).subscribe(responseInArray => {
      this.group = responseInArray;
      this.group['_id'] = this.route.snapshot.paramMap.get('id');
      this.getUpcomingEvents();
      this.getPastEvents();
      this.getCurrentEvents();
      this.getGroupMedia();
    });

  }

  // selectedMediaFunction(eventId) {
  //   this.router.navigate(['/event-details'], {queryParams:{id:eventId}});
  // }

  getGroupMedia() {
    this.groupDetailService.getGroupMedia(this.groupId, this.page).subscribe((responseInArray: []) => {
      this.groupMedia = this.groupMedia.concat(responseInArray);
    });
  }

  getUpcomingEvents() {
    this.groupDetailService.getUpcomingEvents(this.groupId, this.pageUpcoming).subscribe((responseInArray: []) => {
      this.upcomingEvents = this.upcomingEvents.concat(responseInArray);
    });
  }

  getPastEvents() {
    this.groupDetailService.getPastEvents(this.groupId, this.pagePast).subscribe((responseInArray: []) => {
      this.pastEvents = this.pastEvents.concat(responseInArray);
    });
  }

  getCurrentEvents(){
    this.groupDetailService.getCurrentEvents(this.groupId, this.pageCurrent).subscribe((responseInArray: []) => {
      this.currentEvents = this.currentEvents.concat(responseInArray)
    })
  }

  onScrollGroupMedia() {
    this.page++;
    this.getGroupMedia();
  }

  onScrollUpcomingEvents() {
    this.pageUpcoming++;
    this.getUpcomingEvents();
  }

  onScrollPastEvents() {
    this.pagePast++;
    this.getPastEvents();
  }

  onScrollCurrentEvents() {
    this.pageCurrent++;
    this.getCurrentEvents();
  }

  donateToGroup(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onScrollEvents()
  {

  }

  loadEvents()
  {
    
  }

}
