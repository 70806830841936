import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { ShareService } from '@ngx-share/core';
import { HomepageService } from './home-page.service';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { faEllipsisH, faUsers, faPrayingHands, faDonate, faLandmark } from '@fortawesome/free-solid-svg-icons';
import { async } from '@angular/core/testing';

declare var google: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [HomepageService]
})
export class HomePageComponent implements OnInit {
  @ViewChild('searchCity', { static: false })
  public searchElementRef: ElementRef;
  page1
  faEllipsisH = faEllipsisH;
  faUsers = faUsers;
  faPrayingHands = faPrayingHands;
  faDonate = faDonate;
  faLandmark = faLandmark;

  showArrow = false;

  selector: string = '.trending-media-panel';
  events = [];
  feeds = [];

  page = 1;
  pageFeed = 1;
  pageEvent = 1;
  trendingMedia = [];
  feed = [];
  recentEvents = [];
  nearByEvents = [];
  trendingVideos = [];
  feedVideos = [];
  upcommingEvents = [];
  trendingEvents = [];
  groupCounts =
    {
      'charitable': { 'totalGroups': 0, 'activeEvents': 0, 'futureEvents': 0, 'code': 'CHARITABLE', 'name': 'Charitable', 'icon': faDonate },
      'religious': { 'totalGroups': 0, 'activeEvents': 0, 'futureEvents': 0, 'code': 'RELIGIOUS', 'name': 'Religious', 'icon': faPrayingHands },
      'civic': { 'totalGroups': 0, 'activeEvents': 0, 'futureEvents': 0, 'code': 'CIVIC', 'name': 'Civic', 'icon': faLandmark },
      'social': { 'totalGroups': 0, 'activeEvents': 0, 'futureEvents': 0, 'code': 'SOCIAL', 'name': 'Social', 'icon': faUsers },
      'other': { 'totalGroups': 0, 'activeEvents': 0, 'futureEvents': 0, 'code': 'OTHER', 'name': 'Other', 'icon': faEllipsisH }
    };

  groupTypes = [];

  latitude;
  longitude;
  zoom: number = 12;
  city;
  eventType = null;
  selectedMedia
  private geoCoder;
  selectedMediaIndex = 0;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private homepageService: HomepageService,
    private ngZone: NgZone,
    private router: Router,
    private http: HttpClient,
    private commonService: CommonService) {
    // this.setCurrentLocation();

  }

  ngOnInit() {
    // this.latitude = 	40.735657;
    // this.longitude = -74.172363;

    this.page = 1;
    this.pageFeed = 1;
    this.pageEvent = 1;
    this.trendingMedia = [];

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef["nativeElement"],
        {
          types: ["address"]
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place = (google.maps.places.PlaceResult = autocomplete.getPlace());
          if (place.geometry === undefined || place.geometry === null)
            return;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.city = place.formatted_address
          this.zoom = 12;
        })
      })
    });


    this.pushGroupsInArray();
    this.commonService.setPageContext('HOME');
    this.getTrendingMedia();
    this.getTrendingEvent();
    this.getRecentEvents();
    this.getFeed();
    // this.getNearByEvents();
    this.getUpcommingEvents();
    this.getTrendingEventVideo();
    this.getFeedVideo();
    this.showGroupCounts();


  }


  // async initialize() {

  //   var options = {
  //     types: ['(cities)'],
  //     //  componentRestrictions: {country: "us"}
  //   };

  //   var input = document.getElementById('searchTextField');
  //   var autocomplete = new google.maps.places.Autocomplete(await this.searchElementRef.nativeElement, options);
  //   autocomplete.addListener("place_changed", () => {
  //     this.ngZone.run(() => {
  //       // get the place result
  //       let place = google.maps.places.PlaceResult = autocomplete.getPlace();
  //       // verify result
  //       if (place.geometry === undefined || place.geometry === null) {
  //         return;
  //       }
  //       this.city = place.name;
  //     });
  //   });
  // }

  setCurrentLocation() {
    /***********************

    following code for getting value from google service but it will work only if domain with SSL is NOT there,
    This will not give exact current position

    ************************/
    // this.http.get('http://ip-api.com/json', {}).subscribe((coordinates: any) => {
    //   this.latitude = coordinates.lat;
    //   this.longitude = coordinates.lon;
    //   this.zoom = 8;
    //   this.getAddress(this.latitude, this.longitude);
    //   // this.getNearByEvents();
    // }, (err) => {
    //   console.log('API Geolocation error! \n\n' + err);
    // });

    /***********************

    following code for getting value from google service but it will work only if domain with SSL is there.
    This will provide exact current position

    ************************/

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
        this.getAddress(this.latitude, this.longitude);
        // this.getNearByEvents();
      });
    }

  }



  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          // this.city = results[0].formatted_address
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  // clickedMarker(label: string, index: number) {
  //   console.log(`clicked the marker: ${label || index}`)
  // }



  scroll(el: HTMLElement) {
    // el.scrollIntoView();
    this.router.navigate(['/events']);
  }

  loadEvents() {

  }

  onScrollTrendingEvent() {
    this.page++;
    this.getTrendingMedia();
  }

  onScrollFeed() {
    this.pageFeed++;
    this.getFeed();
  }

  onScrollTrendingEventsExceptMedia() {
    this.pageEvent++;
    this.getTrendingEvent();
  }

  searchEvent() {
    this.commonService.setSearchCityValue(this.city);
    this.commonService.setSearchEventTypeValue(this.eventType);
    this.router.navigate(['/events']);
  }

  searchGroup() {
    // this.commonService.setSearchGroupTypeValue(type);
    this.router.navigate(['/groups']);
  }

  getTrendingEvent() {
    this.homepageService.getTrendingEvents(this.pageEvent).subscribe((trendingEvents: []) => {
      // this.trendingEvents = this.trendingEvents.concat(trendingEvents)
      this.trendingEvents = trendingEvents
    })
  }

  getTrendingMedia() {
    this.homepageService.getTrendingMedia(this.page).subscribe((trendingMedia: []) => {
      this.trendingMedia = this.trendingMedia.concat(trendingMedia);
    });
  }

  getFeed() {
    this.homepageService.getFeed(this.pageFeed).subscribe(async (feeds: []) => {
      let mediaArray = await feeds.filter((m: any) => {
        if (m.action == "MEDIAUPLOAD") { return m; }
      });
      this.feeds = this.feeds.concat(mediaArray);
      if (this.feeds.length > 0 && this.selectedMedia == undefined) {
        this.selectedMediaFunction(0)
      }
      if (this.feeds.length < 16) {
        this.onScrollFeed();
      }
    });
  }

getRecentEvents() {
    this.homepageService.getRecentEvents().subscribe((recentEvents: []) => {
      // this.trendingEvents = this.trendingEvents.concat(trendingEvents)
      this.recentEvents = recentEvents
    })
  }

  selectedMediaFunction(index) {
    this.selectedMedia = this.feeds[index];
    // this.isMediaSelected = true;
  }

  getFeedVideo() {
    this.homepageService.getFeedVideo().subscribe((feedVideos: []) => {
      this.feedVideos = feedVideos.filter(media => { if (media['mediaType'] === 'VIDEO') { return media; } });
    });
  }

  // getNearByEvents() {
  //   this.homepageService.getNearByEvents(this.latitude, this.longitude).subscribe( (nearByEvents: []) => {
  //     this.nearByEvents = nearByEvents;
  //   });
  // }

  getUpcommingEvents() {
    this.homepageService.getUpcommingEvents().subscribe((upcommingEvents: []) => {
      this.upcommingEvents = upcommingEvents
      console.log(this.upcommingEvents,'events')
    })
  }

  getTrendingEventVideo() {
    this.homepageService.getTrendingEventVideo().subscribe((trendingVideos: []) => {
      this.trendingVideos = trendingVideos;
    });
  }

  showEvent(eventId, eventType) {
    this.router.navigate(['/event-details'], { queryParams: { id: eventId } });
  }

  showGroup(groupId, users) {
    this.router.navigate(['/group-details/' + groupId]);
  }

  showGroupCounts() {
    this.homepageService.getGroupCounts().subscribe(groupCounts => {

      if (groupCounts["Charitable Organization"]) {
        this.groupTypes.map(type => {
          if (type.code === 'CHARITABLE') {
            type.totalGroups = groupCounts["Charitable Organization"].totalGroups;
            type.activeEvents = groupCounts["Charitable Organization"].activeEvents;
            type.futureEvents = groupCounts["Charitable Organization"].futureEvents;
          }
        });
        // this.groupCounts['charitable'] = groupCounts['Charitable Organization'];
      }
      if (groupCounts["Religious Organization"]) {
        this.groupTypes.map(type => {
          if (type.code === 'RELIGIOUS') {
            type.totalGroups = groupCounts["Religious Organization"].totalGroups;
            type.activeEvents = groupCounts["Religious Organization"].activeEvents;
            type.futureEvents = groupCounts["Religious Organization"].futureEvents;
          }
        });
        // this.groupCounts['religious'] = groupCounts['Religious Organization'];
      }
      if (groupCounts["Civic Organization"]) {
        this.groupTypes.map(type => {
          if (type.code === 'CIVIC') {
            type.totalGroups = groupCounts["Civic Organization"].totalGroups;
            type.activeEvents = groupCounts["Civic Organization"].activeEvents;
            type.futureEvents = groupCounts["Civic Organization"].futureEvents;
          }
        });
        // this.groupCounts['civic'] = groupCounts['Civic Organization'];
      }
      if (groupCounts["Social Organization"]) {
        this.groupTypes.map(type => {
          if (type.code === 'SOCIAL') {
            type.totalGroups = groupCounts["Social Organization"].totalGroups;
            type.activeEvents = groupCounts["Social Organization"].activeEvents;
            type.futureEvents = groupCounts["Social Organization"].futureEvents;
          }
        });
        // this.groupCounts['social'] = groupCounts['Social Organization'];
      }
      if (groupCounts["Other Organization"]) {
        this.groupTypes.map(type => {
          if (type.code === 'OTHER') {
            type.totalGroups = groupCounts["Other Organization"].totalGroups;
            type.activeEvents = groupCounts["Other Organization"].activeEvents;
            type.futureEvents = groupCounts["Other Organization"].futureEvents;
          }
        });
        // this.groupCounts['other'] = groupCounts['Other Organization'];
      }

    });

  }

  eventDetails(eventId) {
    this.router.navigate(['/event-details'], { queryParams: { id: eventId } });
  }

  pushGroupsInArray() {
    this.groupTypes.push(this.groupCounts['charitable']);
    this.groupTypes.push(this.groupCounts['religious']);
    this.groupTypes.push(this.groupCounts['civic']);
    this.groupTypes.push(this.groupCounts['social']);
    this.groupTypes.push(this.groupCounts['other']);
  }


  searchEvents(item) {
    this.page1 = this.commonService.getPageContext();
    this.commonService.setSearchValue(item);
    if (this.page1 == 'EVENT' || this.page1 == 'HOME' || this.page1 == 'ABOUT') {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/groups']);
    }
    this.commonService.onFirstComponentButtonClick(item);
  }

  slideChanged(event) {
    console.log(event, 'event')
    if (event.direction === 'left') {
      this.selectedMediaIndex = this.selectedMediaIndex + 1;
    }
    if (event.direction === 'right') {
      this.selectedMediaIndex = this.selectedMediaIndex - 1;
    }
    this.selectedMedia = this.feeds[this.selectedMediaIndex];
  }

}
