import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() buttonClicked  = new EventEmitter();
  page;
  constructor(
    private router: Router,
    public commonService: CommonService) { }

  ngOnInit() {
    this.page = this.commonService.getPageContext();
    // Checking for current value of page on every page change
    if (this.commonService.subsVar === undefined) {
      this.commonService.subsVar = this.commonService.
      pageContextFunction.subscribe((item: string) => {
        this.page = item;
      });
    }
  }

  searchEvents(item) {
    this.page = this.commonService.getPageContext();
    this.commonService.setSearchValue(item);
    if (this.page == 'EVENT' || this.page == 'HOME' || this.page == 'ABOUT') {
      this.router.navigate(['/events']);
    } else {
      this.router.navigate(['/groups']);
    }
    this.commonService.onFirstComponentButtonClick(item);
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

}
