import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../config/api-config';


@Injectable()
export class PetitionService {
  url = API_URL;

  constructor(private http: HttpClient) {}

  
}
